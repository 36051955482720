import React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BenefitsBox = ({ benefitsData }) => {
  const { description, title, image } = benefitsData;
  const createdImage = getImage(image);
  return (
    <li>
      <GatsbyImage
        image={createdImage}
        alt={`${title} image`}
      />
      <h5 className="bold">{title}</h5>
      <ReactMarkdown source={description} />
    </li>
  );
};

export default BenefitsBox;
