import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import "../../../../styles/Testimonial.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const LifeAtGrootan = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiTeamLives {
        nodes {
          title
          description
          photosList {
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 1140
                  placeholder: NONE
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `);
  const { title, description, photosList } = data.allStrapiTeamLives.nodes[0];
  return (
    <section className="section team">
        <div className="container">
          <div className="row gap-y align-items-center">
            <div className="col-lg-12 pt-2 pb-0">
              <div className="section-heading text-center mb-2">
                <h2>{title}</h2>
                <ReactMarkdown source={description} className="lead mb-2" />
              </div>
            </div>
            <div className="testimonials-slider">
              <div className="swiper-container swiper-container-horizontal pb-2">
                <div className="swiper-wrapper text-center">
                  <Carousel
                    showArrows={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    swipeable={true}
                    interval={3000}
                  >
                    {photosList.map((photo, index) => {
                      const image = getImage(photo.image);
                      return (
                        <div key={index} className="row">
                          <div className="col-12">
                            <GatsbyImage
                              image={image}
                              className="img-responsive text-center"
                              alt="people images from grootan company"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};

export default LifeAtGrootan;
