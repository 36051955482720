import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { createRows } from "../../../../functions";
import ReactMarkdown from "react-markdown";
import BenefitsBox from "../BenefitsBox";

const Benefits = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiCareerBenefits {
        nodes {
          description
          title
          benefitsList {
            description
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 5, width: 60, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `);
  const {
    description,
    title,
    benefitsList
  } = data.allStrapiCareerBenefits.nodes[0];
  let benefitsGroupList = createRows(benefitsList, benefitsList.length, 2);
  return (
    <div className="col-lg-12 perks text-center mt-3">
      <div className="row r">
        <div className="col-lg-12">
          <div className="section-heading mb-4">
            <h2>
              {title}
            </h2>
            <ReactMarkdown source={description} className="lead"/>
          </div>
        </div>
      </div>
      {benefitsGroupList.map((benefitsList, index) => {
        return (
          <ul key={index}>
            {benefitsList.map((benefitsData, index) => (
              <BenefitsBox key={index} benefitsData={benefitsData} />
            ))}
          </ul>
        );
      })}
    </div>
  );
};

export default Benefits;
