import React from "react";
import Profile from "./Profile";

const TeamRow = ({ profileList, index }) => {
  return (
    <>
      <div key={index} className={`row pt-3 pb-3 `}>
        {profileList.map((profileData, index) => (
          <Profile key={index} profileData={profileData} />
        ))}
      </div>
      <hr />
    </>
  );
};

export default TeamRow;
