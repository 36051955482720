import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";
import { createRows } from "../../../functions";
import TeamRow from "./TeamRow";
import LifeAtGrootan from "./sections/LifeAtGrootan";
import Benefits from "./sections/Benefits";
import MetaData from "../../common/MetaData";
import {metaData} from "../../../config"
const Team = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiTeamHeaders {
        nodes {
          description
          title
        }
      }
      allStrapiTeamProfiles {
        nodes {
          profileList {
            image {
              childImageSharp {
                gatsbyImageData(quality: 70, width: 150, placeholder: NONE)
              }
            }
            role
            title
          }
        }
      }
    }
  `);

  const { title, description } = data.allStrapiTeamHeaders.nodes[0];
  const { profileList } = data.allStrapiTeamProfiles.nodes[0];
  let profileGroupList = createRows(profileList, profileList.length, 4);

  return (
    <>
      <MetaData pageTitle="Team" description={metaData.team.description}/>
      <section id="features" className="section team pt-5">
        <div className="container pt-10 pb-1">
          <div className="row gap-y align-items-center">
            <div className="col-lg-12 pt-5">
              <div className="section-heading text-center">
                <h2>{title}</h2>
                <ReactMarkdown source={description} className="lead" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section team design-two">
        <div className="container pt-1 pb-6">
          <div className="row gap-y align-items-center">
            <div className="col-lg-12">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-12 col-lg-12 team-inner-wrap">
                  {profileGroupList.map((profileList, index) => {
                    return <TeamRow key={index} profileList={profileList} index={index} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="row groot-zest">
          {" "}
          <div className="container">
            <div className="row gap-y align-items-center">
              <Benefits />
            </div>
          </div>
        </div>
      </section>
      <LifeAtGrootan />
    </>
  );
};

export default Team;
