import React from "react";
import Layout from "../../src/components/layout";
import Team from "../components/views/team";

const TeamPage = () => {
  return (
    <Layout>
      <Team />
    </Layout>
  );
};

export default TeamPage;
