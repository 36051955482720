import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Profile = ({ profileData }) => {
  const { title, role, image } = profileData;
  const createdImage = getImage(image);
  return (
    <div className="col-lg-3 col-sm-6">
      {title ? (
        <>
          <GatsbyImage
            className="profile-one"
            image={createdImage}
            alt={`${title} image`}
          />
          <h3 className="member-name">{title}</h3>
          <h5 className="member-role">{role}</h5>
        </>
      ) : (
        <a href="/careers/" rel="noreferrer noopener">
          <GatsbyImage className="profile-one" image={createdImage} alt="it can be you image" />
        </a>
      )}
    </div>
  );
};

export default Profile;
